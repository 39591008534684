import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { routeNames } from '../../config';
import { getUserFeatures } from '../../config/sessionService';

interface RouteParameters {
  soldto?: String;
  shipto?: String;
  serial?: String;
}

@autoinject()
export class Go {
  constructor(private router: Router) {}
  protected features = getUserFeatures();

  activate(params: RouteParameters) {
    if (params.shipto || params.soldto) {
      this.router.navigateToRoute("sitelistpage-react", params);
    } else if (this.features.controllerManagement.read && params.serial) {
      this.router.navigateToRoute("controllermanager", params);
    } else {
      this.router.navigateToRoute(routeNames.dashboard)
    }
  }
}
